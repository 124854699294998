import React, { Component } from "react";
import { Button, Form, Grid, Header, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import "../styles/bg.css";
import { AdIosaregionSwipetoslide } from "../components/react-slick/ad-iosaregion-swipetoslide";
import icon from "../images/appicon.png";
import text1 from "../images/account/jg_email_gift_button8.gif";
import iconClose from "../images/account/close.png";
import iconOk from "../images/ok.png";
import iconErr from "../images/err.png";
import iconNil from "../images/nil.png";
import g1 from "../images/account/step1.png";
import g2 from "../images/account/step2.png";
import cookie from "react-cookies";
import { Link } from "gatsby";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import KaisakuApi, { getFullPath } from "../controllers/kaisaku-api";
import { getProfile, getProfileApp } from "./game-support";
import Lambda from "../controllers/utilities/aws-lambda";
import Background5 from '../images/account/nextshow.png';
import Background11 from '../images/account/noshow.png';
import Background7 from '../images/account/nextshow.png';
import Background8 from '../images/btn-008.png';
import Background9 from '../images/btn-009.png';
import input_box from "../images/account/input_box.png";
import mail_box from "../images/account/mail_box.png";
import sendmail from "../images/account/sendmail.png";
import sendmail2 from "../images/account/sendmail2.png";
import confirm1 from "../images/account/confirm1.png";
import confirm2 from "../images/account/confirm2.png";
import showapp from "../images/account/showapp.png";
import showapp2 from "../images/account/showapp2.png";
import withTrans from "../i18n/withTrans";


export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(withTrans(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId: '',
            userName: '',
            email: '',
            newPassWord: '',
            newPassWord2: '',
            accessToken: '',
            message: '',
            messageEmail: '<div>* 密碼只可以輸入英文或數字，長度爲8-16之間</div>',
            bottonCaption: '發送確認',
            expireIn: 60,
            retryIn: 5,
            mode: '',
            applogin: '',

            nurl: ''
        };
        let { userId, accessToken, nurl } = formatQuery(this.props.location.search);
        if (nurl === undefined) {
            nurl = "";
        }
        this.state.userId = userId;
        this.state.accessToken = accessToken;
        this.state.nurl = nurl;
    }

    async componentDidMount() {
        // 獲取用戶資料
        const accessToken1 = cookie.load("_accessToken");
        let accessToken = this.state.accessToken;
        if (accessToken1) accessToken = accessToken1;
        const res = await getProfileApp(accessToken);
        const data = res.data;
        // console.log(res.data);
        this.setState({
            accessToken: accessToken,
            userName: data.username,
            applogin: data.applogin
        })
    }

    CloseToResult() {
        if (this.state.nurl !== ""){
            navigate("/" + this.state.nurl + "/" + this.props.location.search);
        }else{
            navigate("/credit-purchase-ok-result/" + this.props.location.search);
        }
    }

    getDirection = () => {
        // this.setState({ state: this.state });
        const mode = detectOrient();
        if (mode === "PORTRAIT") {
            if (typeof window !== `undefined`) {
                navigate(`/account-configs-page1/${this.props.location.search}`);
            }
        }
    }

    async getEmailStatus() {
        const res = await getProfile(this.state.accessToken);
        const data = res.data;
        if (data.email) {
            clearInterval(this.state.coolDownTimer);
            this.setState({
                bottonCaption: "發送確認"
            });
            this.setState({
                message: "<div style='color:#32CD32'>郵箱已綁定，請關閉返回</div>"
            });
        } else {
            this.resetQueryTimer();
        }
    }

    resetQueryTimer() {
        clearInterval(this.state.queryTimer);
        this.setState({ retryIn: 5 });
        this.state.queryTimer = setInterval(async () => {
            let { retryIn } = this.state;
            console.log(--retryIn);
            this.setState({ retryIn });
            if (retryIn <= 0) {
                clearInterval(this.state.queryTimer);
                await this.getEmailStatus();
            }
        }, 1000);
    }

    startCountdown() {
        this.setState({ expireIn: 60 });
        clearInterval(this.state.queryTimer);
        this.setState({ retryIn: 5 });
        this.state.queryTimer = setInterval(async () => {
            let { retryIn } = this.state;
            console.log(--retryIn);
            this.setState({ retryIn });
            if (retryIn <= 0) {
                clearInterval(this.state.queryTimer);
                await this.getEmailStatus();
            }
        }, 1000);
        this.state.coolDownTimer = setInterval(() => {
            let { expireIn } = this.state;
            console.log(--expireIn);
            this.setState({ expireIn });
            this.setState({
                bottonCaption: expireIn
            });
            // Expire only if there isn't an ongoing api query
            if (expireIn <= 0) {
                clearInterval(this.state.coolDownTimer);
                this.setState({
                    bottonCaption: "發送確認"
                });
                this.setState({
                    message: ""
                });
            }
        }, 1000);
    }

    async attachEmail(email) {
        let response = {
            success: false,
            data: null,
            errorMsgs: ""
        };
        const res = await Lambda.post(getFullPath("attachEmail"), { email }, this.state.accessToken);
        if (res.status === 200) {
            response.success = true;
            response.data = res.data;
        } else {
            response.errorMsgs = res.data.message;
        }
        return response;
    }

    async changePasswordSDK(newPassword, accessToken) {
        return await Lambda.post(getFullPath("changePasswordSDK"), { newPassword }, accessToken);
    }

    async setShowBindEmail(accessToken) {
        return await Lambda.post(getFullPath("setShowBindEmail"), null, accessToken);
    }

    render() {
        if (this.state.mode === '') {
            const mode = detectOrient();
            if (mode === "PORTRAIT") {
                if (typeof window !== `undefined`) {
                    navigate(`/account-configs-page1/${this.props.location.search}`);
                }
            } else {
                this.setState({
                    mode: "LANDSCAPE"
                });
            }
        }
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                width: "100%",
                background: "transparent",
            }} verticalAlign='middle'>
                <Grid.Column >
                    <Modal
                        style={{ width: '96%', borderRadius: '10px 10px 0px 0px', bottom: "0px" }}
                        open
                    >
                        <div style={{
                            width: '100%',
                            borderRadius: '10px 10px 0px 0px',
                            display: ''
                        }}>

                            <div style={{
                                height: '32px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold',
                                border: '1px solid #D6D6D6',
                                borderRadius: '10px 10px 0px 0px',
                                opacity: '1'
                            }}
                            >

                                <div style={{
                                    color: '#686868',
                                    height: '31px',
                                    float: "right", textAlign: "left",
                                    paddingTop: '6px',
                                    paddingRight: "10px",
                                    paddingLeft: "10px",
                                }}
                                    onClick={() => {
                                        this.CloseToResult();
                                    }}
                                >
                                    <Image style={{
                                        height: "5vh",
                                        minHeight: "16px"
                                    }} centered
                                        src={iconClose}
                                    />

                                </div>
                                <div style={{ position: "absolute", left: "0", top: "0", right: "40px", fontWeight: "bold", color: '#686868', float: "left", textAlign: "center", paddingLeft: "20px", paddingTop: '8px' }}>帳號：{this.state.userName}</div>

                            </div>
                            <div style={{ width: '100%', height: "75vh", overflowY: "auto" }}
                            >
                                {/* <div style={{ width: "100%", textAlign: "center", paddingTop: "5px", fontSize: "1.3rem", fontWeight: "bold", color: "#ff3a6f" }}>
                                    親~ 你還未綁定郵箱哦！要不要現在就來綁定一下~？
                                </div> */}
                                <div style={{  width: "100%" }}>
                                    <div style={{ float: "left", width: "50%" }}>
                                    <div style={{ marginLeft:"20px",width: "80%" }}>
                                        <div style={{ width: "100%", textAlign: "center", padding: "10px", fontSize: "1.3rem", fontWeight: "bold", color: "#686868" }}>
                                        <div style={{ overflow: "auto", paddingLeft: "10px", paddingBottom: "8px" }}>
                                                <div style={{ float: "left" }}><Image style={{
                                                    width:"100%"
                                                }} centered
                                                    src={text1}
                                                /></div>
                                            </div>
                                            <div style={{ overflow: "auto", paddingLeft: "10px", paddingBottom: "4px" }}>
                                                <div style={{ float: "left" }}><Image style={{
                                                    height: "24px",
                                                }} centered
                                                    src={g1}
                                                /></div>
                                            </div>
                                            <div style={{
                                                width: "100%",
                                                overflow: "hidden",
                                                color: "#fff"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    backgroundColor: "#fff"
                                                }}
                                                >
                                                    <div className="blackDiv" style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                        backgroundImage: `url(${input_box})`, backgroundSize: '100% 100%'
                                                    }}
                                                    >
                                                        <Form.Input
                                                            style={{
                                                                marginLeft: "5%", width: "90%", height: "38px", marginTop: "-5px"
                                                            }}
                                                            fluid
                                                            onChange={(e, data) => this.setState({ email: data.value })} placeholder="輸入電郵地址"
                                                        />
                                                    </div>
                                                </div>

                                                <div style={{ padding: "1px", textAlign: "center", color: "red", fontWeight: "normal", fontSize: "12px" }}
                                                    dangerouslySetInnerHTML={{ __html: decodeURI(this.state.message) }}
                                                ></div>
                                                <div style={{ color: "red", textAlign: "left", fontSize: "12px", fontWeight: "normal", marginLeft: "2%", paddingLeft: "12px",paddingBottom:"1px" }}>
                                                    * 如收件箱沒有收到認證郵件，請查看垃圾信箱
                                                </div>
                                                <div className="field"
                                                    style={{
                                                        width: "100%",
                                                        height: "56px",
                                                        paddingTop: "10px",
                                                        textAlign: "center",
                                                        color: "#fff",
                                                        backgroundImage: `url(${(this.state.bottonCaption === "發送確認") ? sendmail : sendmail2})`, backgroundSize: '100% 100%',
                                                    }}
                                                    onClick={async () => {
                                                        if (this.state.bottonCaption === "發送確認") {
                                                            this.setState({
                                                                message: ""
                                                            });
                                                            if ((this.state.email === "")) {
                                                                this.setState({
                                                                    message: "<div>郵箱不能爲空</div>"
                                                                });
                                                            } else if (!validateEmail(this.state.email)) {
                                                                this.setState({
                                                                    message: "<div>郵箱格式有誤~</div>"
                                                                });
                                                            } else {
                                                                // 進行綁定操作
                                                                console.log("進行綁定操作");
                                                                const res = await getProfile(this.state.accessToken);
                                                                const data = res.data;
                                                                if (data.email) {
                                                                    this.setState({
                                                                        message: "<div>郵箱已綁定，重綁請到主介面>帳號管理>綁定郵箱</div>"
                                                                    });
                                                                } else {
                                                                    const res = await this.attachEmail(this.state.email);
                                                                    if (res.success) {
                                                                        this.setState({
                                                                            message: "<div style='color:#32CD32'>已成功發送~請確認郵件</div>"
                                                                        });
                                                                        this.setState({
                                                                            bottonCaption: "60"
                                                                        });
                                                                        this.startCountdown();
                                                                    } else {
                                                                        this.setState({
                                                                            message: `<div>${this.props.t(res.errorMsgs)}</div>`
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                >
                                                </div>
                                            </div>
                                            <div style={{ width: "100%", textAlign: "center", fontSize: "1.3rem", fontWeight: "bold", color: "#686868" }}>
                                                <div style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                    border: "1px solid #FFF",
                                                    overflowY: "auto",
                                                    Height: "48px",
                                                    color: "#fff",
                                                }}>

                                                    {/* <div style={{
                                                        width: "100%",
                                                        height: "80px",
                                                        backgroundImage: `url(${(this.state.applogin === "1") ? showapp : showapp2})`, backgroundSize: '100% 100%',
                                                    }} onClick={() => {
                                                        console.log("下载apk");
                                                        const newurl = "https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download";
                                                        window.location.href = newurl;
                                                    }}>
                                                        &nbsp;
                                                    </div> */}
                                                    <div style={{ width: "100%" }}>
                                    <Image style={{
                                        width: "100%",
                                        // height: "4.2rem",
                                        // backgroundImage: `url(${(this.state.applogin === "1") ? showapp : showapp2})`, backgroundSize: '100% 100%',
                                    }} onClick={() => {
                                        console.log("下载apk");
                                        const newurl = "https://e0aaqfo398.execute-api.ap-southeast-1.amazonaws.com/prod/getApkUrl302?gameId=2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4&r=download";
                                        window.location.href = newurl;
                                    }} centered src={(this.state.applogin === "1") ? showapp : showapp2}/>
                                       
                                </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div style={{ float: "right", width: "50%" }}>
                                <div style={{ marginLeft:"20px",width: "80%" }}>
                                    <div style={{ width: "100%", textAlign: "center", padding: "10px", fontSize: "1.3rem", fontWeight: "bold", color: "#686868" }}>
                                        <div style={{ overflow: "auto", paddingLeft: "10px", paddingBottom: "4px" }}>
                                            <div style={{ float: "left" }}><Image style={{
                                                height: "24px",
                                            }} centered
                                                src={g2}
                                            /></div>
                                        </div>

                                        <div className="blackDiv" style={{
                                            padding: "10px",
                                            width: "100%",
                                            backgroundImage: `url(${input_box})`, backgroundSize: '100% 100%'
                                        }}
                                        >
                                            <Form.Input type="password"
                                                style={{
                                                    marginLeft: "5%", width: "90%", height: "36px", marginTop: "-5px"
                                                }}
                                                fluid
                                                onChange={(e, data) => this.setState({ newPassWord: data.value })} placeholder="輸入新的密碼"
                                            />
                                        </div>
                                        <div className="blackDiv" style={{
                                            padding: "10px",
                                            width: "100%",
                                            backgroundImage: `url(${input_box})`, backgroundSize: '100% 100%'
                                        }}
                                        >
                                            <Form.Input type="password"
                                                style={{
                                                    marginLeft: "5%", width: "90%", height: "36px", marginTop: "-5px"
                                                }}
                                                fluid
                                                onChange={(e, data) => this.setState({ newPassWord2: data.value })} placeholder="再次輸入新的密碼"
                                            />
                                        </div>

                                        <div style={{
                                            width: "100%",
                                            textAlign: "center",
                                            overflowY: "auto",
                                            color: "#fff",
                                        }}
                                            onClick={async () => {
                                                this.setState({
                                                    messageEmail: ""
                                                });
                                                if ((this.state.newPassWord === "") || (this.state.newPassWord2 === "")) {
                                                    this.setState({
                                                        messageEmail: "<div>* 新密碼與確認新密碼不能爲空</div>"
                                                    });
                                                } else if (this.state.newPassWord !== this.state.newPassWord2) {
                                                    this.setState({
                                                        messageEmail: "<div>* 新密碼與確認新密碼不一致</div>"
                                                    });
                                                } else {
                                                    // 进行修改密码的操作
                                                    console.log("进行修改密码的操作");
                                                    const password = this.state.newPassWord;
                                                    if (validatePassWd(password)) {
                                                        // 发送进行保存密码
                                                        const res = await this.changePasswordSDK(this.state.newPassWord, this.state.accessToken);
                                                        if (res.status === 200) {
                                                            this.setState({
                                                                messageEmail: "<div style='color:#32CD32'>密碼保存成功</div>"
                                                            });
                                                        } else {
                                                            this.setState({
                                                                messageEmail: "<div>密碼保存失敗</div>"
                                                            });
                                                        }

                                                    } else {
                                                        this.setState({
                                                            messageEmail: "<div>* 密碼只可以輸入英文或數字，長度爲8-16之間</div>"
                                                        });
                                                    }
                                                }

                                            }}
                                        >
                                            <div style={{ padding: "1px", textAlign: "left", color: "red", fontSize: "12px", fontWeight: "normal", paddingLeft: "12px" }}
                                                dangerouslySetInnerHTML={{ __html: decodeURI(this.state.messageEmail) }}
                                            ></div>
                                            <div style={{ color: "red", textAlign: "left", fontSize: "12px", fontWeight: "normal", paddingLeft: "12px",paddingBottom:"1px" }}>
                                                * 其後可在帳號管理中更改電郵或密碼
                                            </div>
                                            <div style={{
                                                width: "100%",
                                                paddingTop: "8px",
                                                height: "50px",
                                                paddingLeft: "20px",
                                                backgroundImage: `url(${returnImage(this.state.newPassWord, this.state.newPassWord2)})`, backgroundSize: '100% 100%',
                                            }}
                                            >
                                                &nbsp;
                                            </div>

                                            <div>
                                            <div style={{
                                    width: "100%", textAlign: "center", paddingTop: "6px", fontSize: "1.3rem", fontWeight: "bold", color: "#686868",
                                    overflowY: "auto"
                                }}>
                                    {(this.state.nurl === '') && (<div style={{
                                        width: "49%", float: "left", 
                                        padding: "0px",
                                        fontWeight: "normal",
                                        color: "#fff",
                                        height: "50px",
                                        borderRadius: '5px 5px 5px 5px',
                                        marginRight: "2px",
                                        backgroundImage: `url(${Background11})`, backgroundSize: '100% 100%',
                                        textShadow: "1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000"
                                    }}
                                        onClick={async () => {
                                            // 插入一条记录，已表示不需要再提示
                                            console.log("不再显示");
                                            await this.setShowBindEmail(this.state.accessToken);
                                            this.CloseToResult();
                                        }}
                                    >&nbsp;</div>)}
                                    <div style={{
                                        width: "49%", float: "right", 
                                        padding: "0px",
                                        borderRadius: '5px 5px 5px 5px',
                                        height: "50px",
                                        color: "#fff",
                                        backgroundImage: `url(${Background5})`, backgroundSize: '100% 100%',
                                        textShadow: "1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000"
                                    }}
                                        onClick={() => {
                                            this.CloseToResult();
                                        }}
                                    >&nbsp;</div>
                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
}));


function returnImage(newPassWord, newPassWord2) {
    if ((newPassWord === "") || (newPassWord2 === "")) return confirm2;
    if (newPassWord !== newPassWord2) return confirm2;
    if (newPassWord === newPassWord2) {
        if (validatePassWd(newPassWord)) {
            return confirm1;
        } else {
            return confirm2;
        }
    }
    return confirm2;
}

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}


function validatePassWd(password) {
    const str = password;
    if (str == null || str.length < 8 || str.length > 16) {
        return false;
    }
    const reg1 = new RegExp(/^[0-9A-Za-z]+$/);
    if (!reg1.test(str)) {
        return false;
    }
    return getPasswordErrors(password);
    // const reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
    // if (reg.test(str)) {
    //     return true;
    // } else {
    //     return false;
    // }
}


function getPasswordErrors(password) {
    if (startsWithSpace(password)) {
        return false;
    }
    if (!sixCharsOrMore(password)) {
        return false;
    }
    if (!containsOnlyLegalChars(password)) {
        return false;
    }
    return true;
}

function startsWithSpace(str) {
    const startsWithSpace = /^ /g;
    return startsWithSpace.test(str);
}

function sixCharsOrMore(str) {
    const sixCharsOrMore = /.{6,}/g;
    return sixCharsOrMore.test(str);
}

function containsOnlyLegalChars(str) {
    const containsOnlyLegalChars = /^(?=.*[A-Za-z\d @$!%*#?&^+])[A-Za-z\d @$!%*#?&^+]*$/g;
    return containsOnlyLegalChars.test(str);
}


function validateEmail(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}